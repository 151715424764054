<template>
    <v-row>
        <template v-for="type in ['Addresses','Employers']">
            <template v-for="(item,index) in item[type]">
                <v-col cols="6" md="6" lg="4" :key="`${type}-${index}`">
                    <psi-detail-card
                        :headingTitle="getHeading(type)"
                        :items="item.items"
                        dense
                        :icon="getIcon(type)"
                    ></psi-detail-card>
                </v-col>
            </template>
        </template>
    </v-row>
</template>
<script>
export default {
    name: "order-details",
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        getIcon(type) {
            return type === "Addresses" ? "mdi-map-marker" : "mdi-domain";
        },
        getHeading(type) {
            return type === "Addresses" ? "Address" : "Employer";
        },
    },
};
</script>

<style scoped>
</style>