var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm._l(["Addresses", "Employers"], function(type) {
        return [
          _vm._l(_vm.item[type], function(item, index) {
            return [
              _c(
                "v-col",
                {
                  key: type + "-" + index,
                  attrs: { cols: "6", md: "6", lg: "4" }
                },
                [
                  _c("psi-detail-card", {
                    attrs: {
                      headingTitle: _vm.getHeading(type),
                      items: item.items,
                      dense: "",
                      icon: _vm.getIcon(type)
                    }
                  })
                ],
                1
              )
            ]
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }